import { isNonEmpty } from '@sgme/fp';
import { SgwtHelpCenter, type SgwtHelpCenterHTMLElement, useSgwtWidgets } from '@sgwt/sgwt-widgets-react';
import { useCallback, useEffect, useRef } from 'react';

interface HelpCenterProps {
  className?: string;
  withHandler?: boolean;
  topic?: string;
}

// Source = https://developer.sgmarkets.com/docs/web/widgets/structural-widgets/sgwt-help-center.html
type HelpCenterNextEvent = CustomEvent<{
  action: string;
  categoryId: string;
  topicId: string;
  title: string;
}>;
type HelpCenterPreviousEvent = CustomEvent<{
  nb: number;
}>;
type HelpCenterTopicEvent = CustomEvent<{ topicId: string }>;
type HelpCenterCategoryEvent = CustomEvent<{ categoryId: string }>;

const HelpCenterWebAnalyticsCategory = 'Help center';

export function HelpCenter({ className = '', withHandler = false, topic }: HelpCenterProps): JSX.Element {
  const { sgwtWebAnalytics } = useSgwtWidgets();

  const innerRef = useRef<SgwtHelpCenterHTMLElement>();

  const onWidgetReady = (element: SgwtHelpCenterHTMLElement) => {
    innerRef.current = element;
  };

  const onClickHandler = useCallback(() => {
    if (isNonEmpty(topic)) {
      innerRef.current?.topic?.(topic);
    } else {
      innerRef.current?.open?.();
    }
  }, [topic]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: useEffect execution must depends "innerRef?.current"
  useEffect(() => {
    const widget = innerRef?.current;
    if (!widget) {
      return;
    }

    //
    // Help center listeners
    //

    function handleOpen() {
      sgwtWebAnalytics?.trackEvent(HelpCenterWebAnalyticsCategory, 'Open');
    }

    function handleClose() {
      sgwtWebAnalytics?.trackEvent(HelpCenterWebAnalyticsCategory, 'Close');
    }

    function handleNext(event: Event) {
      const detail = (event as HelpCenterNextEvent).detail;
      sgwtWebAnalytics?.trackEvent(
        HelpCenterWebAnalyticsCategory,
        `Click on "${detail.categoryId ?? detail.topicId}"`,
        detail.title,
      );
    }

    function handlePrevious(event: Event) {
      const detail = (event as HelpCenterPreviousEvent).detail;
      sgwtWebAnalytics?.trackEvent(HelpCenterWebAnalyticsCategory, `Click on previous (${detail.nb})"`);
    }

    function handleTopic(event: Event) {
      const detail = (event as HelpCenterTopicEvent).detail;
      sgwtWebAnalytics?.trackEvent(HelpCenterWebAnalyticsCategory, `Click on "${detail.topicId}"`);
    }

    function handleCategory(event: Event) {
      const detail = (event as HelpCenterCategoryEvent).detail;
      sgwtWebAnalytics?.trackEvent(HelpCenterWebAnalyticsCategory, `Click on "${detail.categoryId}"`);
    }

    function handleOpenForm() {
      sgwtWebAnalytics?.trackEvent(HelpCenterWebAnalyticsCategory, 'Open Message Form');
    }

    function handleFormSend() {
      sgwtWebAnalytics?.trackEvent(HelpCenterWebAnalyticsCategory, 'Send Message');
    }

    //
    // register listeners
    //

    widget.addEventListener('sgwt-help-center--open', handleOpen);
    widget.addEventListener('sgwt-help-center--close', handleClose);
    widget.addEventListener('sgwt-help-center--next', handleNext);
    widget.addEventListener('sgwt-help-center--previous', handlePrevious);
    widget.addEventListener('sgwt-help-center--send', handleFormSend);
    widget.addEventListener('sgwt-help-center--category', handleCategory);
    widget.addEventListener('sgwt-help-center--topic', handleTopic);
    widget.addEventListener('sgwt-help-center--form', handleOpenForm);

    return () => {
      //
      // unregister lineners
      //
      widget.removeEventListener('sgwt-help-center--open', handleOpen);
      widget.removeEventListener('sgwt-help-center--close', handleClose);
      widget.removeEventListener('sgwt-help-center--next', handleNext);
      widget.removeEventListener('sgwt-help-center--previous', handlePrevious);
      widget.removeEventListener('sgwt-help-center--send', handleFormSend);
      widget.removeEventListener('sgwt-help-center--category', handleCategory);
      widget.removeEventListener('sgwt-help-center--topic', handleTopic);
      widget.removeEventListener('sgwt-help-center--form', handleOpenForm);
    };
  }, [sgwtWebAnalytics, innerRef?.current]);

  return (
    <>
      {withHandler && (
        <button
          className={`btn btn-flat-primary btn-icon ${className ?? ''}`}
          onClick={onClickHandler}
          onMouseUp={() => innerRef.current?.close?.()}
          type="button"
        >
          <em className="icon">help_outline</em>
        </button>
      )}

      <aside className="text-primary">
        <SgwtHelpCenter
          sg-connect-support="sg-connect-v2"
          application-id="sgmarkets-wmc"
          mail-subject="[SGWT Help Center] - [WMC] - User message"
          onWidgetReady={onWidgetReady}
        />
      </aside>
    </>
  );
}
